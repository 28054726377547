import Button from '../Button'

import { navigate } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { gsap } from 'gsap'
import { default as React, useEffect, useRef } from 'react'
import WhatWeOfferCard from './WhatWeOfferCard'
import './styles.scss'

const PublishingWhatWeOffer = ({
    heading,
    subHeading,
    sideTag,
    buttonText,
    description,
    backgroundImage,
    cards,
}) => {
    const _image = getImage(backgroundImage.gatsbyImageData)
    const _background = convertToBgImage(_image)
    // const stripeOneRef = useRef(null)
    // const stripeTwoRef = useRef(null)
    // const stripeThreeRef = useRef(null)
    // useEffect(() => {
    //   let mm = gsap.matchMedia()
    //   const ctx = gsap.context(() => {
    //     mm.add("(min-width: 1024px)", (context) => {
    //       gsap.fromTo(
    //         stripeOneRef.current,
    //         {
    //           duration: 2,
    //           autoAlpha: 1,
    //         },
    //         {
    //           y: 1400,
    //           x: -1400,
    //           autoAlpha: 0,
    //           duration: 2,
    //           repeat: -1,
    //           repeatDelay: 4,
    //         }
    //       )
    //       gsap.fromTo(
    //         stripeTwoRef.current,
    //         {
    //           duration: 3,
    //           autoAlpha: 1,
    //         },
    //         {
    //           y: 2200,
    //           x: -2200,
    //           autoAlpha: 0,
    //           duration: 3,
    //           repeat: -1,
    //           repeatDelay: 4,
    //           delay: 2,
    //         }
    //       )
    //     })

    //     mm.add("(min-width: 768px)", (context) => {
    //       gsap.fromTo(
    //         stripeThreeRef.current,
    //         {
    //           duration: 3.5,
    //           autoAlpha: 1,
    //         },
    //         {
    //           y: 2200,
    //           x: -2200,
    //           autoAlpha: 0,
    //           duration: 3.5,
    //           repeat: -1,
    //           repeatDelay: 4,
    //           delay: 3,
    //         }
    //       )
    //     })
    //   })
    //   return () => ctx.revert()
    // }, [])
    return (
        <BackgroundImage
            {..._background}
            Tag="section"
            style={{
                backgroundPosition: 'top center',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            {/* <div
        ref={stripeOneRef}
        role="status"
        className="hidden pointer-events-none lg:block w-[30rem] h-40 animate-pulse absolute -top-[20rem] left-1/3 bg-gradient-to-r from-transparent via-white to-transparent -rotate-45 opacity-70"
      ></div>
      <div
        ref={stripeTwoRef}
        role="status"
        className="hidden pointer-events-none lg:block w-[60rem] h-40 animate-pulse absolute -top-[40rem] left-[80%] bg-gradient-to-r from-transparent via-white to-transparent -rotate-45 opacity-70"
      ></div>
      <div
        ref={stripeThreeRef}
        role="status"
        className="w-[45rem] pointer-events-none h-40 animate-pulse absolute -top-[30rem] left-[120%] bg-gradient-to-r from-transparent via-white to-transparent -rotate-45 opacity-70"
      ></div> */}

            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black  to-transparent"></div>
            <div
                id="publishing-what-we-offer"
                className="py-12 md:py-16 xl:py-28 bg-black bg-opacity-70 relative z-20"
            >
                <div className="container mx-auto relative px-10">
                    <div className="grid grid-cols-1 md:grid-cols-14">
                        <div className="col-span-full xl:col-span-12 xl:col-start-2">
                            <div className="flex flex-start mb-28 items-start gap-10">
                                <div className="hidden md:flex sideways-text uppercase rotate-180 justify-end mr-4 font-semibold text-md mt-8 md:mt-0 text-light-boulder">
                                    {sideTag}
                                </div>
                                <div>
                                    <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-secondary uppercase">
                                        {heading}
                                    </h2>
                                    <h2 className="flex-1 text-3xl md:text-4xl lg:text-5xl font-bold text-white uppercase">
                                        {subHeading}
                                    </h2>
                                    <p className="text-light-boulder max-w-xl mt-12 text-lg">
                                        {description}
                                    </p>
                                </div>
                            </div>
                            <div className="flex">
                                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mt-8 md:mt-0 w-full">
                                    {cards.map(
                                        ({ description, heading, icon }, i) => (
                                            <React.Fragment key={i}>
                                                <WhatWeOfferCard
                                                    {...{
                                                        description,
                                                        heading,
                                                        icon,
                                                    }}
                                                />
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                            </div>
                            {/* <div className="mt-8 md:mt-12 text-center">
                <Button
                  className="mx-auto bg-secondary border-secondary"
                  clickHandler={() => {
                    document
                      .getElementById("publishing-pitch-form")
                      .scrollIntoView({ behaviour: "smooth" })
                  }}
                >
                  {buttonText}
                </Button>
              </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

export default PublishingWhatWeOffer
